import React from "react"
import { Link } from "react-router-dom"
import SocialMedia from "../SocialMedia"

const Footer = () => {
	const auth = localStorage.getItem("JWT")
	if (auth !== null) {
		return null
	}
	return (
		<footer className="w-full py-phone md:py-tablet px-phone md:px-tablet lg:px-desktop flex flex-col justify-center items-center gap-[30px] footer_custom">
			<div className="w-full flex flex-col md:flex-row justify-start items-center gap-[30px] md:gap-[50px] lg:gap-[100px]">
				<Link to="/" className="text-black">
					<img
						className="w-[100px]"
						src="/images/newLogo.png"
						alt="Amazinf pet grooming logo"
					/>
				</Link>

				<div className="w-full flex gap-[10%]">
					<ul>
						<li className="text-black font-medium">Menu</li>
						<li>
							<Link
								to="/"
								className="text-gray hover:no-underline hover:text-main"
							>
								Home
							</Link>
						</li>
						<li className="cursor-pointer">
							<Link
								to="/about"
								className="text-gray hover:no-underline hover:text-main"
							>
								About us
							</Link>
						</li>
						<li className="cursor-pointer itemMainDesktopMenu">
							<Link
								to="/gallery"
								className="text-gray hover:no-underline hover:text-main"
							>
								Gallery
							</Link>
						</li>
						<li className="cursor-pointer itemMainDesktopMenu">
							<Link
								to="/contact"
								className="text-gray hover:no-underline hover:text-main"
							>
								Contact
							</Link>
						</li>
						<li className="cursor-pointer itemMainDesktopMenu">
							<Link
								to="/clients_registration"
								className="text-gray hover:no-underline hover:text-main"
							>
								Register
							</Link>
						</li>
						<li className="cursor-pointer itemMainDesktopMenu">
							<Link
								to="/schedule"
								className="text-gray hover:no-underline hover:text-main"
							>
								Book Appoinment
							</Link>
						</li>
					</ul>
					<ul>
						<li className="text-black font-medium">Contact</li>
						<li className="text-gray cursor-pointer">
							<a
								href="tel:+16473687220"
								className="text-gray hover:text-main hover:no-underline"
							>
								(+1) 647 368-7220 (Cambridge)
							</a>
						</li>
						<li className="text-gray cursor-pointer">
							<a
								href="tel:+19058789009"
								className="text-gray hover:text-main hover:no-underline"
							>
								(+1) 905 878-9009 (Milton)
							</a>
						</li>
						<li className="text-gray cursor-pointer">
							<a
								href="tel:+19058785557"
								className="text-gray hover:text-main hover:no-underline"
							>
								(+1) 905 878-5557 (Milton)
							</a>
						</li>
						<li className="text-gray cursor-pointer">
							<a
								href="mailto:info@amazingpetgrooming.ca"
								className="text-gray hover:text-main hover:no-underline"
							>
								info@amazingpetgrooming.ca
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div className="pt-[30px] border-t-[1px] border-gray w-full flex felx-col md:flex-row items-center justify-center gap-[20px] md:gap-[30%]">
				<p className="text-gray text-center text-[14px] mb-0">
					© 2025 Amazing Pet Grooming |
					<a href="/auth/login" className="text-main">
						{" "}
						Staff Login
					</a>
				</p>
			</div>
			<SocialMedia />
		</footer>
	)
}

export default Footer
