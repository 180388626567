import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import API from "../../utils/API"
import BootstrapTable from "react-bootstrap-table-next"
import MainCalendar from "../../components/Calendars/MainCalendar"
import SearchClients from "../../components/SearchClients"
import { FaSquareArrowUpRight } from "react-icons/fa6"
import { CgSandClock } from "react-icons/cg"
import { Table, Button, Form, Input } from "reactstrap"
import "./style.scss"
import paginationFactory from "react-bootstrap-table2-paginator"
import WaitListAdmin from "../../components/WaitListAdmin"
import ModalSearchResult from "./ModalSearchAll"
import ModalToAddClients from "./ModalToAddClients"
import ModalOptionAddClientsDisabled from "./ModalOptionAddClientsDisabled"
import { Helmet } from "react-helmet"
import moment from "moment"
import { SiAdblock } from "react-icons/si"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import ModalGeneralPurpose from "../../components/Modals/ModalGeneralPurpose"
import DogBreedSearch from "../../components/DogBreedSearch"
import { BiSolidDog } from "react-icons/bi"

const TODAY_DATE = moment(new Date()).format("YYYY-MM-DD")

const Index = () => {
	const { setCommissions } = useContext(RegistrationContext)
	const [dates, setDates] = useState({
		paola: new Date(),
		paola_cambridge: new Date(),
		diana: new Date(),
		frank: new Date(),
		baths: new Date(),
		placeholder: new Date(),
	})
	const [clients, setClients] = useState([])
	const [dogsBreeds, setDogsBreeds] = useState([])
	const [breedsLookupModal, setBreedsLookupModal] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [hideOtherCalendarsButAdmins, setHideOtherCalendarsButAdmins] =
		useState(localStorage.getItem("USERNAME") === "paoyepes" ? true : false)
	const [error, setError] = useState(false)
	const [toggleSearchClientByPhoneForm, setToggleSearchClientByPhoneForm] =
		useState(false)
	const [modalToAddClients, setModalToAddClients] = useState(false)
	const [modalSearchResult, setModalSearchResult] = useState(false)
	const [showDaycare, setShowDaycare] = useState(false)
	const [searchAppointmentsForm, setSearchAppointmentsForm] = useState(false)
	const [searchAppointmentField, setSearchAppointmentField] = useState("")
	const [searchResultsDetails, setSearchResultsDetails] = useState([])
	const [jobType, setJobType] = useState("")
	const [toggleClientsTable, setToggleClientsTable] = useState(false)
	const [toggleCalendars, setToggleCalendars] = useState(true)
	const [waitList, setWaitList] = useState(false)
	const [loadingClientsTable, setLoadingClientsTable] = useState(false)
	const [client_id_field, setClient_id_field] = useState("")
	const [clientIdResult, setClientIdResult] = useState("")
	const [city, setCity] = useState("")
	const [date, setDate] = useState(new Date())
	const [authorized, setAuthorized] = useState(false)
	const [triggerFetchEvents, setTriggerFetchEvents] = useState(false)
	const accessString = localStorage.getItem("JWT")

	const handleNavigation = (direction) => {
		setDates((prevDates) => {
			const updatedDates = {}
			const increment = direction === "next" ? 1 : -1

			Object.keys(prevDates).forEach((calendarId) => {
				updatedDates[calendarId] = new Date(
					prevDates[calendarId].getFullYear(),
					prevDates[calendarId].getMonth(),
					prevDates[calendarId].getDate() + increment
				)
			})

			return updatedDates
		})
	}

	const handleCalendarNavigate = (calendarId, newDate) => {
		setDates((prevDates) => ({
			...prevDates,
			[calendarId]: newDate,
		}))
	}

	const fetchAuth = async () => {
		await axios
			.get("/auth/employees_profile", {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then((res) => setJobType(res.data.jobType))
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		if (
			jobType === "admin" ||
			jobType === "receptionist" ||
			jobType === "receptionistCalendars"
		) {
			setAuthorized(true)
		}
	}, [jobType])

	useEffect(() => {
		const accessString = localStorage.getItem("JWT")

		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual"
		}
		window.scrollTo(0, 0)

		if (accessString == null) {
			setIsLoading(false)
			setError(true)
		} else {
			try {
				fetchAuth()
				setIsLoading(false)
				setError(false)
				setCity("milton")
				localStorage.setItem("CITY", "milton")
				getAllCommissions()
			} catch (error) {
				console.error(error.response)
				setError(true)
			}
		}
	}, [])

	const getAllCommissions = async () => {
		await axios
			.get("/auth/salaries/hourly/commission/" + TODAY_DATE)
			.then((res) => {
				setCommissions(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const handleToggleClientsTable = () => {
		getAllClients()
		localStorage.setItem("CITY", "milton")
		setToggleClientsTable(!toggleClientsTable)
		setToggleCalendars(false)
		setWaitList(false)
		setShowDaycare(false)
		if (toggleSearchClientByPhoneForm) {
			setToggleSearchClientByPhoneForm(false)
		}
	}

	const handleToggleCalendars = () => {
		setToggleCalendars(!toggleCalendars)
		setWaitList(false)
		setToggleClientsTable(false)
		setShowDaycare(false)
	}
	const handleToggleWaitlist = () => {
		setWaitList(!waitList)
		setToggleCalendars(false)
		setToggleClientsTable(false)
		setShowDaycare(false)
		setToggleSearchClientByPhoneForm(false)
		setSearchAppointmentsForm(false)
	}

	const toggleFormToSearchByPhone = () => {
		setToggleSearchClientByPhoneForm(!toggleSearchClientByPhoneForm)
		setWaitList(false)
		setShowDaycare(false)
		if (searchAppointmentsForm) {
			setSearchAppointmentsForm(false)
		} else if (toggleClientsTable) {
			setToggleClientsTable(false)
			setToggleCalendars(true)
		}
	}

	const handleDeleteClient = (id) => {
		const city = localStorage.getItem("CITY")
		let url
		const clientDeletedBy = {
			deletedAt: moment(new Date()).format("YYYY/MM/DD hh:mm"),
			deletedBy: localStorage.getItem("USERNAME"),
		}

		if (city === "milton") {
			url = API.softDeleteClient
		} else {
			url = API.softDeleteClientCambridge
		}
		if (
			window.confirm(`Are you sure you wish to delete this client permanently?`)
		) {
			url(id, clientDeletedBy)
				.then(() => {
					alert(
						"Client with Id number: " + id + " has been successfully deleted!"
					)
					city === "milton" ? getAllClients() : getAllClientsCambridge()
				})
				.catch((err) => console.log(err))
			// props.history.push("/auth/reception");
		}
	}

	const getAllClients = async () => {
		const accessString = localStorage.getItem("JWT")
		setLoadingClientsTable(true)

		await axios
			.get("/api/clients", {
				headers: { jwt: `${accessString}` },
			})
			.then((res) => {
				if (res.data.status === "error") {
					throw new Error(res.data.message)
				}
				const filteredClients = res.data.filter((client) => !client.deletedAt)
				setClients(filteredClients)
				setLoadingClientsTable(false)
			})
			.catch((err) => console.log(err))
	}

	const getAllDogBreeds = async () => {
		const accessString = localStorage.getItem("JWT")

		await axios
			.get("/api/dogs/breeds", {
				headers: { jwt: `${accessString}` },
			})
			.then((res) => {
				setDogsBreeds(res.data)
			})
			.catch((err) => console.log(err))
	}

	const dogBreedsHandler = async () => {
		if (dogsBreeds.length === 0) await getAllDogBreeds()
		setBreedsLookupModal(!breedsLookupModal)
	}

	const searchById = async (e) => {
		e.preventDefault()

		if (isNaN(client_id_field)) {
			return alert("Only numbers here please!")
		}
		if (client_id_field === "") {
			return alert("Please enter a number")
		}

		let result = clients.filter((word) => word.id === parseInt(client_id_field))
		e.target.reset()
		setClientIdResult(result)
	}

	const toggleSearchAppointments = () => {
		setSearchAppointmentsForm(!searchAppointmentsForm)
		setWaitList(false)
		setShowDaycare(false)
		if (toggleSearchClientByPhoneForm) {
			setToggleSearchClientByPhoneForm(false)
		}
	}

	const toggleDaycare = () => {
		setShowDaycare(!showDaycare)
		setSearchAppointmentsForm(false)
		setToggleCalendars(false)
		setToggleClientsTable(false)
		setWaitList(false)
		if (toggleSearchClientByPhoneForm) {
			setToggleSearchClientByPhoneForm(false)
		}
	}

	const toggleModalSearchResults = () => {
		setModalSearchResult(!modalSearchResult)
	}

	const handleSearchAppointmentsSubmit = async (e) => {
		e !== undefined && e.preventDefault()

		await axios
			.get(`/schedule/calendar/search/all/${searchAppointmentField}`, {
				title: searchAppointmentField,
			})
			.then((res) => {
				const filteredEvents = res.data.filter(
					(event) =>
						event.city === "milton" && !event.deletedAt && !event.isBeingBooked
				)
				toggleModalSearchResults()
				setSearchResultsDetails(filteredEvents)
			})
			.catch((err) => console.log(err))
	}

	const showEventOnCalaendar = (eventId) => {
		console.log(eventId)
	}

	if (isLoading) {
		return (
			<div
				style={{
					marginTop: "120px",
					marginLeft: "10%",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				Loading...if it does not respond, refresh this page
			</div>
		)
	}

	if (error) {
		console.log(error)
		return (
			<div
				style={{
					marginLeft: "10%",
					marginTop: "120px",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				...Problem fetching user data. Please login again
				<span role="img" aria-label="Face With Rolling Eyes Emoji">
					🙄
				</span>
			</div>
		)
	}

	if (!authorized) {
		return (
			<div className="mt-24 text-3xl text-center">
				<SiAdblock size={40} className="inline-block text-red-500" /> <br />{" "}
				Unauthorized
			</div>
		)
	}

	if (
		jobType === "receptionist" ||
		jobType === "receptionistCalendars" ||
		jobType === "admin"
	) {
		const searchResultsById = clientIdResult.length ? (
			clientIdResult.map((result) => (
				<tr key={result.id} className="border-b bg-cyan-50 border-gray">
					<td className="px-4 py-2">{result.id}</td>
					<td className="px-4 py-2">{result.firstName}</td>
					<td className="px-4 py-2">{result.lastName}</td>
					<td className="px-4 py-2">{result.primaryPhoneNumber}</td>
					<td className="px-4 py-2">
						{result.Pets.map((pet) => (
							<div key={pet.id} className="mb-1">
								<p className="text-gray">
									<span className="font-bold">{pet.name}</span> -- {pet.breed}
								</p>
								{result.Pets.length > 1 && <hr className="my-1 border-gray" />}
							</div>
						))}
					</td>
					<td className="px-4 py-2">
						<Link
							to={`/clients/${city}/${result.id}`}
							className="inline-block w-full px-4 py-2 text-center text-white bg-blue-800 rounded-md"
						>
							More Info...
						</Link>
					</td>
				</tr>
			))
		) : (
			<tr className="text-center">
				<td colSpan="6" className="py-4 bg-gray-100">
					That Id does not exist!
				</td>
			</tr>
		)

		//clients Table Rendering
		const defaultSortedBy = [
			{
				dataField: "id",
				order: "desc", // or desc
			},
		]

		const columns = [
			{
				dataField: "id",
				text: "ID",
				sort: true,
				order: "asc", // or desc
				headerStyle: (colum, colIndex) => {
					let widthColumn
					if (window.innerWidth < 451) {
						widthColumn = "40px"
					} else {
						widthColumn = "80px"
					}
					return { width: widthColumn, textAlign: "center" }
				},
			},
			{
				dataField: "lastName",
				text: "Last Name",
				sort: true,
			},
			{
				dataField: "firstName",
				text: "First Name",
				sort: true,
			},
			{
				dataField: "primaryPhoneNumber",
				text: "Primary phone",
				sort: true,
			},
			{
				dataField: "cellphone",
				text: "Cell",
				sort: true,
			},

			{
				dataField: "Pets",
				text: "Pets",
				formatter: (cell) => {
					return cell.map((pet) => {
						return (
							<ul className="pets-in-table" key={pet.id}>
								<li>{pet.name}</li>
								<span>{pet.breed}</span>
							</ul>
						)
					})
				},
				sort: true,
			},
			{
				dataField: "actions",
				text: "Actions",
				isDummyField: true,
				csvExport: false,
				formatter: (cell, row) => {
					return (
						<div>
							<Link
								className="btn btn-info btn-table-clients"
								to={`/clients/${city}/${row.id}`}
							>
								More Info
							</Link>
							<button
								className="btn btn-danger btn-table-clients"
								onClick={() => handleDeleteClient(row.id)}
							>
								Delete
							</button>
						</div>
					)
				},
			},
		]

		return (
			<div className="pt-16 mt-12 bg-pink-50">
				<Helmet>
					<title>Dashboard</title>
				</Helmet>
				<div className="">
					<div className="row justify-content-center control-panel-container">
						<h1 className="text-center flex sm:text-xl px-3 text-[20px] flex-wrap">
							<span className="px-1 mr-2 text-white border-2 border-pink-500 bg-pink">
								Milton
							</span>
							Main Desk Control Panel
							<Link
								className="inline-block w-full sm:inline sm:w-72"
								to={"/auth/reception/cambridge"}
								color="dark"
							>
								<button className="px-2 py-1 ml-2 text-sm text-white bg-purple-600 rounded-sm hover:bg-purple-500 sm:text-xl">
									<FaSquareArrowUpRight className="inline-block" /> Switch to
									Cambridge
								</button>
							</Link>
						</h1>
						<div className="col-md-12 ">
							<h1 className="controlPanelHeadingMobile">Control Panel</h1>
							<div className="flex flex-wrap justify-center gap-2 mt-2">
								{/* Add Clients Btn */}
								<button
									onClick={handleToggleWaitlist}
									className="px-2 border-2 rounded-sm bg-cyan-300 hover:bg-cyan-200 border-cyan-700"
								>
									Waitlist <CgSandClock className="inline-block" />
								</button>
								<button
									onClick={handleToggleClientsTable}
									className="px-2 py-1 bg-yellow-100 border-2 border-yellow-700 rounded-sm hover:bg-yellow-50"
								>
									Clients Table <i className="fas fa-users"></i>
								</button>

								{/* See calendars */}
								{!toggleCalendars ? (
									<button
										className="px-2 py-1 text-white rounded-sm bg-pink hover:bg-pink-400"
										onClick={handleToggleCalendars}
									>
										Schedule <i className="fas fa-clipboard-list"></i>
									</button>
								) : null}

								{/* Search client by phone Btn */}
								<button
									className="px-2 py-1 bg-green-300 border-2 border-green-700 rounded-sm hover:bg-green-200"
									onClick={toggleFormToSearchByPhone}
								>
									Search Clients <i className="fas fa-search"></i>
								</button>

								<button
									color="dark"
									className="px-2 py-1 text-white bg-blue-800 rounded-sm hover:bg-slate-500"
									onClick={toggleSearchAppointments}
								>
									<i className="fas fa-search"></i> All Appointments Search
								</button>
								<button
									onClick={toggleDaycare}
									className="px-2 py-1 text-white bg-yellow-600 border-2 border-yellow-900 rounded-sm"
								>
									Daycare
								</button>
								<button
									onClick={dogBreedsHandler}
									className="px-2 py-1 text-white bg-purple-500 border-2 border-purple-900 rounded-sm"
								>
									<BiSolidDog className="inline mr-2" />
									Breed Lookup
								</button>
							</div>
						</div>
					</div>
					<hr />

					{showDaycare && (
						<div
							id="DaycareCalendar"
							className={"col-xl-12 col-md-12 col-xs-12"}
						>
							<MainCalendar
								isFromEditClients={false}
								triggerFetchEvents={triggerFetchEvents}
								setTriggerFetchEvents={setTriggerFetchEvents}
								date={date}
								isBather={false}
								city="milton"
								groomer={"daycare"}
								step={390}
								timeSlots={1}
								min={new Date(2019, 10, 0, 7, 0, 0)}
								max={new Date(2019, 10, 0, 19, 0, 0)}
								standardControls
							/>
						</div>
					)}

					{searchAppointmentsForm ? (
						<div className="mx-auto border shadow-lg w-50">
							<form onSubmit={handleSearchAppointmentsSubmit}>
								<input
									placeholder="Search by: phone, pet name, breed."
									onChange={(e) => setSearchAppointmentField(e.target.value)}
									required
									className="input-search-appointments"
									type="text"
									name="searchAppointmentField"
								/>
								<div className="text-center">
									<button className="m-2 btn btn-info" type="submit">
										Search All Calendars <i className="fas fa-search"></i>
									</button>
								</div>
							</form>
						</div>
					) : null}
					{localStorage.getItem("USERNAME") === "paoyepes" ||
					localStorage.getItem("USERNAME") === "alexyepes" ? (
						//  MODAL TO ADD CLIENTS
						<ModalToAddClients
							city={city}
							isOpen={modalToAddClients}
							toggle={() => setModalToAddClients(!modalToAddClients)}
						/>
					) : (
						// MODAL TO SHOW OPTION DISABLED CLIENTS
						<ModalOptionAddClientsDisabled
							isOpen={modalToAddClients}
							toggle={() => setModalToAddClients(!modalToAddClients)}
						/>
					)}
				</div>

				{/* Forms to search clients by name, last name, first name, and pet name */}
				{toggleSearchClientByPhoneForm ? (
					<SearchClients clients={clients} />
				) : null}
				{toggleClientsTable ? (
					<div className="clients-list-table">
						<h1 className="clientsList-mainTitle" id="clientsList">
							Clients List <i className="fas fa-user"></i>
						</h1>
						<Form
							onSubmit={searchById}
							className="flex-row w-full p-2 mx-auto text-center border-2 border-slate-500 sm:w-1/2 d-flex align-items-center"
						>
							<label
								className="mx-2 h5 text-slate-500 searchByClientLabel"
								htmlFor=""
							>
								Search by Client Id
							</label>
							<Input
								className="flex-grow-1"
								onChange={(e) => setClient_id_field(e.target.value)}
								name="client_id_field"
								type="text"
							/>
							<Button color="info" className="ml-2">
								Search
							</Button>
						</Form>

						{clientIdResult === "" ? null : (
							<div className="w-[99%] mx-auto">
								<Table className="mt-2 border border-gray">
									<thead className="bg-cyan-300">
										<tr>
											<th className="px-4 py-2 border-b">Client #</th>
											<th className="px-4 py-2 border-b">First Name</th>
											<th className="px-4 py-2 border-b">Last Name</th>
											<th className="px-4 py-2 border-b">Primary Phone</th>
											<th className="px-4 py-2 border-b">Pets</th>
											<th className="px-4 py-2 border-b">Action</th>
										</tr>
									</thead>
									<tbody>{searchResultsById}</tbody>
								</Table>
							</div>
						)}
						<div className="react-table-component">
							{loadingClientsTable ? (
								<div>
									<h3>
										Loading Clients...{" "}
										<i className="fa fa-refresh fa-spin "></i>
									</h3>
								</div>
							) : (
								<div className="table-responsive">
									<BootstrapTable
										className="react-table-component"
										keyField="id"
										data={clients}
										columns={columns}
										pagination={paginationFactory()}
										defaultSorted={defaultSortedBy}
										striped
										wrapperClasses="table-responsive"
									/>
								</div>
							)}
						</div>
					</div>
				) : null}

				{waitList ? <WaitListAdmin /> : null}

				{toggleCalendars ? (
					<div>
						<div className="flex-row justify-between hidden gap-2 sm:flex">
							<button
								className="px-2 text-xl text-white bg-black border"
								onClick={() => handleNavigation("back")}
							>
								&#x2190; All Calendars Back
							</button>
							<button
								className="px-2 text-xl text-white bg-black border"
								onClick={() =>
									setDates({
										paola: new Date(),
										paola_cambridge: new Date(),
										claudia: new Date(),
										diana: new Date(),
										frank: new Date(),
										baths: new Date(),
										mercedes: new Date(),
									})
								}
							>
								RESET All Calendars
							</button>
							<button
								className="px-2 text-xl text-white bg-black border"
								onClick={() => handleNavigation("next")}
							>
								All Calendars Next &rarr;
							</button>
						</div>

						<div className="row calendars-group">
							{/* PAOLA MILTON */}
							<div
								id="AdminCalendar"
								className={
									localStorage.getItem("USERNAME") === "paoyepes"
										? "col-xl-6 col-md-6 col-xs-12 cal-all"
										: "col-xl-4 col-md-4 col-xs-12 cal-all"
								}
							>
								<MainCalendar
									isFromEditClients={false}
									triggerFetchEvents={triggerFetchEvents}
									setTriggerFetchEvents={setTriggerFetchEvents}
									date={dates.paola}
									onNavigate={(newDate) =>
										handleCalendarNavigate("paola", newDate)
									}
									isBather={false}
									city="milton"
									groomer={"paola"}
									step={15}
									timeSlots={4}
									min={new Date(2019, 10, 0, 7, 0, 0)}
									max={new Date(2019, 10, 0, 18, 0, 0)}
								/>
							</div>
							{/* PAOLA CAMBRIDGE */}
							{localStorage.getItem("USERNAME") === "paoyepes" && (
								<div
									id="AdminCalendar"
									className="col-xl-6 col-md-6 col-xs-12 cal-all"
								>
									<MainCalendar
										isFromEditClients={false}
										triggerFetchEvents={triggerFetchEvents}
										setTriggerFetchEvents={setTriggerFetchEvents}
										date={dates.paola_cambridge}
										onNavigate={(newDate) =>
											handleCalendarNavigate("paola_cambridge", newDate)
										}
										isBather={false}
										city="cambridge"
										groomer={"paola"}
										step={15}
										timeSlots={4}
										min={new Date(2019, 10, 0, 8, 30, 0)}
										max={new Date(2019, 10, 0, 19, 0, 0)}
									/>
								</div>
							)}

							<>
								{/* DIANA MILTON */}
								<div
									id="Emp2Calendar"
									className={
										localStorage.getItem("USERNAME") === "paoyepes"
											? "col-xl-6 col-md-6 col-xs-12 cal-all"
											: "col-xl-4 col-md-4 col-xs-12 cal-all"
									}
								>
									<MainCalendar
										isFromEditClients={false}
										triggerFetchEvents={triggerFetchEvents}
										setTriggerFetchEvents={setTriggerFetchEvents}
										date={dates.diana}
										onNavigate={(newDate) =>
											handleCalendarNavigate("diana", newDate)
										}
										isBather={false}
										city="milton"
										groomer={"diana"}
										step={30}
										timeSlots={2}
										min={new Date(2019, 10, 0, 7, 0, 0)}
										max={new Date(2019, 10, 0, 19, 0, 0)}
									/>
								</div>

								{/* BATHS MILTON */}
								<div
									id="Emp4Calendar"
									className="col-xl-4 col-md-4 col-xs-12 cal-all"
								>
									<MainCalendar
										isFromEditClients={false}
										triggerFetchEvents={triggerFetchEvents}
										setTriggerFetchEvents={setTriggerFetchEvents}
										date={dates.baths}
										onNavigate={(newDate) =>
											handleCalendarNavigate("baths", newDate)
										}
										city="milton"
										isBather={true}
										groomer={"bather"}
										step={15}
										timeSlots={2}
										min={new Date(2019, 10, 0, 7, 0, 0)}
										max={new Date(2019, 10, 0, 18, 0, 0)}
									/>
								</div>
								{/* FRANK MILTON */}
								<div
									id="Emp3Calendar"
									className="col-xl-4 col-md-4 col-xs-12 cal-all"
								>
									<MainCalendar
										isFromEditClients={false}
										triggerFetchEvents={triggerFetchEvents}
										setTriggerFetchEvents={setTriggerFetchEvents}
										date={dates.frank}
										onNavigate={(newDate) =>
											handleCalendarNavigate("frank", newDate)
										}
										isBather={false}
										city="milton"
										groomer={"frank"}
										step={15}
										timeSlots={2}
										min={new Date(2019, 10, 0, 7, 0, 0)}
										max={new Date(2019, 10, 0, 19, 0, 0)}
									/>
								</div>
								{/* NEW GROOMER MILTON */}
								{/* <div
									id="Emp6Calendar"
									className="col-xl-4 col-md-4 col-xs-12 cal-all"
								>
									<MainCalendar
										isFromEditClients={false}
										triggerFetchEvents={triggerFetchEvents}
										setTriggerFetchEvents={setTriggerFetchEvents}
										date={dates.placeholder}
										onNavigate={(newDate) =>
											handleCalendarNavigate("placeholder", newDate)
										}
										isBather={false}
										city="milton"
										groomer={"placeholder"}
										step={15}
										timeSlots={2}
										min={new Date(2019, 10, 0, 7, 0, 0)}
										max={new Date(2019, 10, 0, 19, 0, 0)}
									/>
								</div> */}
							</>
							{/* {!hideOtherCalendarsButAdmins && (
              )} */}
						</div>
					</div>
				) : null}

				<ModalSearchResult
					modalSearchResults={modalSearchResult}
					toggleModalSearchResults={toggleModalSearchResults}
					searchResultsDetails={searchResultsDetails}
					searchAppointmentField={searchAppointmentField}
					showEventOnCalaendar={showEventOnCalaendar}
					handleSearchAppointmentsSubmit={() =>
						handleSearchAppointmentsSubmit()
					}
					setSearchResultsDetails={setSearchResultsDetails}
					triggerFetchEvents={triggerFetchEvents}
					setTriggerFetchEvents={setTriggerFetchEvents}
				/>

				<ModalGeneralPurpose
					modalSize="md"
					isModalOpen={breedsLookupModal}
					toggleModal={() => setBreedsLookupModal(!breedsLookupModal)}
					modalProps={{
						breeds: dogsBreeds,
					}}
					modalContent={DogBreedSearch}
				/>
			</div>
		)
	} else {
		return (
			<div
				style={{
					marginTop: "100px",
					marginLeft: "10%",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				You don't have permission to access this page
				<span role="img" aria-label="Face With Rolling Eyes Emoji">
					🙄
				</span>
			</div>
		)
	}
}

export default Index
