import React, { useState, useContext } from "react"
import { Form, FormGroup, Label, Input, Button } from "reactstrap"
import axios from "axios"
import "./Signin.scss"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import { useNavigate } from "react-router-dom"
import CustomInput from "../CustomInput"
import { CustomButton } from "../CustomButton"

export default function SignIn() {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [errorMessage, setErrorMessage] = useState("")
	const navigate = useNavigate()
	const {
		setLimitedAccessToCambridgeAdmin,
		setSwitchToOldToolbar,
		showPassword,
		setShowPassword,
	} = useContext(RegistrationContext)

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (email === "" || password === "") {
			return alert("Both fields are required")
		} else {
			try {
				const response = await axios.post("/auth/login", {
					email,
					password,
				})
				if (response.data.accountStatus === "suspended") {
					return alert(
						"Your account is inactive at this time. Contact your manager for support"
					)
				} else if (response.data.accountStatus === "terminated") {
					return alert(
						"Your account is currently inactive. Unauthorized access attempts are tracked and may result in legal action. If you believe this is an error, please contact management."
					)
				} else {
					localStorage.setItem("JWT", response.data.token)
					localStorage.setItem("USERNAME", response.data.username)
					localStorage.setItem("JOBTYPE", response.data.jobType)
					localStorage.setItem(
						"LIMITED_ACCESS_TO_CAMBRIDGE",
						response.data.limitedAccessToCambridgeAdmin
					)
					setLimitedAccessToCambridgeAdmin(
						response.data.limitedAccessToCambridgeAdmin
					)
					setSwitchToOldToolbar(true)
					localStorage.setItem("OLD_TOOLBAR", "active")
					if (response.data.jobType === "admin") {
						navigate("/auth/admin_dashboard")
					}
					if (response.data.jobType === "customer") {
						navigate("/auth/customer")
					}
					if (
						response.data.jobType === "receptionist" ||
						response.data.jobType === "receptionistCalendars"
					) {
						navigate("/auth/reception")
					}
					if (
						response.data.jobType === "groomer1" ||
						response.data.jobType === "groomer2" ||
						response.data.jobType === "groomer3" ||
						response.data.jobType === "groomer6" ||
						response.data.jobType === "groomer7"
					) {
						navigate("/auth/employees_profile")
					}
				}
			} catch (error) {
				console.error(error.response)
				setErrorMessage(error.response.data.message)
			}
		}
	}

	return (
		<div className="w-full h-[100vh] md:h-[calc(100vh-90px)] flex items-center justify-center p-phone md:p-tablet lg:p-desktop">
			<div className=" w-full md:w-1/2 h-[60%] md:h-full border-main bg-lightBlue rounded-default p-phone flex flex-col gap-[30px]">
				<img
					alt="-signin"
					src="/images/new-logo.png"
					className="logo-signin-form"
				/>
				<form
					className="w-full h-full flex flex-col justify-between"
					onSubmit={handleSubmit}
				>
					<div className="w-full flex flex-col gap-[10px]">
						<h5 className="text-black font-black text-[20px] md:text-[30px] mb-[10px] text-center">
							👤 Staff Sign In
						</h5>
						<CustomInput
							type="email"
							id="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="* Email"
							color="#65BFF8"
						/>{" "}
						<CustomInput
							type={showPassword ? "text" : "password"}
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="* Password"
							color="#65BFF8"
							showPassword={showPassword}
							setShowPassword={setShowPassword}
							useEyeIcon
						/>
					</div>
					<div className="w-full relative">
						<CustomButton fullWidth>Login</CustomButton>
					</div>
					<p className="text-red-400 text-center inline-block">
						{errorMessage}
					</p>
				</form>
			</div>
		</div>
	)
}
