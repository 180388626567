import axios from "axios"
import React, { useState, useEffect } from "react"
import MainCalendar from "../../components/Calendars/MainCalendar"
import "./style.css"
import { SiAdblock } from "react-icons/si"
import { PiSmileySadBold } from "react-icons/pi"

const Profile = () => {
	const accessString = localStorage.getItem("JWT")
	const [jobType, setJobType] = useState("")
	const [accountStatus, setAccountStatus] = useState("")
	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState(false)

	useEffect(() => {
		window.scrollTo(0, 0)
		fetchAuth()

		if (accessString == null) {
			setIsLoading(false)
			setError(true)
		} else {
			try {
				fetchAuth()
			} catch (error) {
				console.error(error.response)
				setError(true)
			}
		}
		// eslint-disable-next-line
	}, [accessString])

	const fetchAuth = async () =>
		await axios
			.get("/auth/employees_profile", {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then((res) => {
				if (res.data.accountStatus === "suspended") {
					setAccountStatus(res.data.accountStatus)
					setIsLoading(false)
				} else {
					setJobType(res.data.jobType)
					setIsLoading(false)
				}
			})
			.catch((err) => console.log(err))

	if (accountStatus === "suspended") {
		return (
			<div className="text-3xl text-center mt-24">
				<SiAdblock size={40} className="inline-block text-red-500" /> <br />{" "}
				Your account is inactive at the moment <br />
				contact your manager for more details
			</div>
		)
	}
	if (error) {
		return (
			<div
				style={{
					marginLeft: "10%",
					fontSize: "30px",
					height: "100vh",
					marginTop: "120px",
				}}
			>
				...Problem fetching user data. Please login again
				<span role="img" aria-label="Face With Rolling Eyes Emoji">
					🙄
				</span>
			</div>
		)
	}
	if (isLoading) {
		return (
			<div
				style={{
					marginTop: "120px",
					marginLeft: "10%",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				Loading Amazing Pet Grooming Data...try logging in again
			</div>
		)
	}

	// CLAUDIA
	// if (jobType === "groomer1") {
	// 	return (
	// 		<div className="row mt-5">
	// 			<div
	// 				id="Emp1Calendar"
	// 				className="col-xl-6 col-md-6 col-sm-12 cal-all mb-5"
	// 			>
	// 				<MainCalendar
	// 					city="milton"
	// 					groomer="claudia"
	// 					step={30}
	// 					timeSlots={2}
	// 					min={new Date(2019, 10, 0, 5, 0, 0)}
	// 					max={new Date(2019, 10, 0, 19, 0, 0)}
	// 					standardControls
	// 				/>
	// 			</div>
	// 			<div
	// 				id="Emp1Calendar"
	// 				className="col-xl-6 col-md-6 col-sm-12 cal-all mb-5"
	// 			>
	// 				<MainCalendar
	// 					city="cambridge"
	// 					groomer="claudia"
	// 					step={30}
	// 					timeSlots={2}
	// 					min={new Date(2019, 10, 0, 7, 0, 0)}
	// 					max={new Date(2019, 10, 0, 19, 0, 0)}
	// 					standardControls
	// 				/>
	// 			</div>
	// 		</div>
	// 	)
	// }

	// DIANA
	if (jobType === "groomer2") {
		return (
			<div className="cal-main-container">
				<main style={{ marginTop: "80px" }}></main>
				<div
					id="Emp1Calendar"
					className="col-xl-12 col-md-12 col-12 cal-all mt-5 mb-5"
				>
					<MainCalendar
						city="milton"
						groomer={"diana"}
						step={30}
						timeSlots={2}
						min={new Date(2019, 10, 0, 7, 0, 0)}
						max={new Date(2019, 10, 0, 19, 0, 0)}
						standardControls
					/>
				</div>
				<div
					id="Emp1Calendar"
					className="col-xl-12 col-md-12 col-12 cal-all mt-5 mb-5"
				>
					<MainCalendar
						city="milton"
						groomer={"frank"}
						step={15}
						timeSlots={2}
						min={new Date(2019, 10, 0, 7, 0, 0)}
						max={new Date(2019, 10, 0, 15, 0, 0)}
						standardControls
					/>
				</div>
			</div>
		)
	}

	// PEPPA
	if (jobType === "groomer6") {
		return (
			<div className="cal-main-container">
				<main style={{ marginTop: "80px" }}></main>
				<div
					id="Emp6Calendar"
					className="col-xl-12 col-md-12 col-12 cal-all mt-5 mb-5"
				>
					<MainCalendar
						city="cambridge"
						groomer={"peppa"}
						step={30}
						timeSlots={2}
						min={new Date(2019, 10, 0, 7, 0, 0)}
						max={new Date(2019, 10, 0, 19, 0, 0)}
						standardControls
					/>
				</div>
				<div
					id="Emp8Calendar"
					className="col-xl-12 col-md-12 col-12 cal-all mt-5 mb-5"
				>
					<MainCalendar
						city="cambridge"
						groomer={"mercedes"}
						step={30}
						timeSlots={2}
						min={new Date(2019, 10, 0, 7, 0, 0)}
						max={new Date(2019, 10, 0, 19, 0, 0)}
						standardControls
					/>
				</div>
				<div
					id="Emp6Calendar"
					className="col-xl-12 col-md-12 col-12 cal-all mt-5 mb-5"
				>
					<MainCalendar
						city="cambridge"
						groomer={"bath_Cambridge"}
						step={15}
						timeSlots={2}
						min={new Date(2019, 10, 0, 7, 0, 0)}
						max={new Date(2019, 10, 0, 15, 0, 0)}
						standardControls
					/>
				</div>
			</div>
		)
	}

	return (
		<div className="text-2xl flex flex-col justify-center min-h-screen items-center text-center">
			<p className="block">Nothing to show, something went wrong!</p>
			<p>
				<PiSmileySadBold size={50} className="block" />
			</p>
		</div>
	)
}

export default Profile
