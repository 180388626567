import React, { useLayoutEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import LocationCard from "../../components/LocationCard"
import { locations } from "../../utils/constants"
import SocialMedia from "../../components/SocialMedia"
import { gsap, Power4, Back } from "gsap"
import SplitType from "split-type"
import { MdAdsClick } from "react-icons/md"
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	Label,
	Form,
	FormGroup,
} from "reactstrap"
import DOMPurify from "dompurify"
import ReCAPTCHA from "react-google-recaptcha"
import { useEffect } from "react"

const Index = () => {
	const container = useRef(null)
	const recaptchaRef = useRef(null)
	const [modal, setModal] = useState(false)
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		location: "Milton",
		message: "",
		honeypot: "",
	})
	const [isLoading, setIsLoading] = useState(false)
	const [recaptchaToken, setRecaptchaToken] = useState(null)

	useEffect(() => {
		if (!modal) {
			setRecaptchaToken(null)
		}
	}, [modal])

	const toggle = () => setModal(!modal)

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const handleRecaptchaChange = (token) => {
		setRecaptchaToken(token)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		if (formData.honeypot) {
			console.warn("Bot detected! Ignoring submission.")
			return
		}

		if (!recaptchaToken) {
			alert("Please complete the reCAPTCHA verification.")
			return
		}

		const sanitizedData = {
			name: DOMPurify.sanitize(formData.name.trim()),
			email: DOMPurify.sanitize(formData.email.trim()),
			location: formData.location,
			message: DOMPurify.sanitize(formData.message.trim()),
			recaptchaToken,
		}

		if (!sanitizedData.name || !sanitizedData.email || !sanitizedData.message) {
			alert("Please fill in all fields.")
			return
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (!emailRegex.test(sanitizedData.email)) {
			alert("Please enter a valid email address.")
			return
		}

		setIsLoading(true)

		try {
			const response = await fetch("/api/send-message", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(sanitizedData),
			})

			const result = await response.json()

			if (response.ok) {
				alert("Message sent successfully!")
				setFormData({
					name: "",
					email: "",
					location: "Milton",
					message: "",
					honeypot: "",
				})
				setRecaptchaToken(null)
				recaptchaRef.current?.reset()
				setModal(false)
			} else {
				alert(result.message || "Failed to send message.")
			}
		} catch (error) {
			alert("An error occurred. Please try again later.")
		} finally {
			setIsLoading(false)
		}
	}

	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			const tl = gsap.timeline({ defaults: { ease: Power4.easeOut } })

			const splitTitleParent = new SplitType("#contactTitle", {
				types: "lines",
				lineClass: "lineParentContactTitle",
			})
			const splitTitle = new SplitType(".lineParentContactTitle", {
				types: "lines",
			})

			tl.fromTo(
				splitTitle.lines,
				{ y: 100, opacity: 0 },
				{ y: 0, opacity: 1, duration: 1, delay: 0.2, stagger: 0.2 }
			)
			tl.fromTo(
				".contactInfo",
				{ y: 10, opacity: 0 },
				{ y: 0, opacity: 1, duration: 1 },
				"-=0.5"
			)
			tl.fromTo(
				".mapContact",
				{ y: 10, opacity: 0 },
				{ y: 0, opacity: 1, duration: 1 },
				"-=0.5"
			)
			tl.fromTo(
				".contactImg",
				{ scale: 0.5, x: -200, opacity: 0 },
				{
					scale: 1,
					x: -10,
					opacity: 1,
					duration: 0.5,
					ease: Back.easeInOut.config(1),
				},
				"-=0.8"
			)
		}, container)

		return () => ctx.revert()
	}, [])

	return (
		<section
			className="w-full h-full lg:h-[calc(100vh-90px)] p-phone md:px-tablet md:pt-tabler lg:px-desktop lg:pt-tablet flex flex-col gap-[50px] bg-white"
			ref={container}
		>
			<Helmet>
				<title>Contact Us | Amazing Grooming Pet</title>
				<meta
					name="description"
					content="Two locations to choose from, Cambridge and Milton."
				/>
			</Helmet>
			<main className="w-full h-full flex flex-col gap-[50px] relative">
				<h4
					className="text-main pt-12 text-[38px] leading-[50px] md:text-[60px] md:leading-[70px] font-black text-center"
					id="contactTitle"
				>
					Contact us
				</h4>

				<button
					className="mx-auto bg-main text-white p-2 rounded-xl hover:text-main hover:shadow-lg hover:border-main border-2 transition-all duration-300"
					onClick={toggle}
				>
					Send us a message
					<MdAdsClick size={30} className="inline ml-2" />
				</button>

				<div className="w-full h-full">
					<div className="w-full h-[5%] p-phone rounded-t-default flex justify-between items-center border-[1px] border-black">
						<a
							href="mailto:info@amazingpetgrooming.ca"
							className="text-black hover:no-underline contactInfo"
						>
							info@amazingpetgrooming.ca
						</a>
						<SocialMedia className="contactInfo" />
					</div>
					<div className="w-full h-[90%] p-phone border-x-[1px] border-b-[1px] border-black rounded-b-default flex flex-col lg:flex-row gap-[50px] relative">
						{locations.map((location) => (
							<LocationCard
								location={location}
								key={location.address}
								className="mapContact"
							/>
						))}
						<div className="absolute bg-black w-full h-[1px] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:w-[1px] lg:h-full lg:top-0 lg:translate-y-0" />
					</div>
				</div>
			</main>

			<Modal isOpen={modal} toggle={toggle} centered>
				<ModalHeader toggle={toggle}>Send us a message</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit}>
						<FormGroup>
							<Label for="name">Your Name</Label>
							<Input
								type="text"
								name="name"
								id="name"
								value={formData.name}
								onChange={handleChange}
								required
							/>
						</FormGroup>
						<FormGroup>
							<Label for="email">Your Email</Label>
							<Input
								type="email"
								name="email"
								id="email"
								value={formData.email}
								onChange={handleChange}
								required
							/>
						</FormGroup>
						<FormGroup>
							<Label for="location">Which Location</Label>
							<Input
								type="select"
								name="location"
								id="location"
								value={formData.location}
								onChange={handleChange}
								required
							>
								<option>Milton</option>
								<option>Cambridge</option>
							</Input>
						</FormGroup>
						<FormGroup>
							<Label for="message">Message</Label>
							<Input
								type="textarea"
								name="message"
								id="message"
								value={formData.message}
								onChange={handleChange}
								required
							/>
						</FormGroup>

						<ReCAPTCHA
							ref={recaptchaRef}
							sitekey="6Le9BAErAAAAAGuHsxwcGu1J62It22ipNx7uJG3t"
							onChange={handleRecaptchaChange}
						/>

						<ModalFooter>
							<Button color="secondary" onClick={toggle}>
								Cancel
							</Button>
							<Button color="primary" type="submit" disabled={isLoading}>
								{isLoading ? "Sending..." : "Send"}
							</Button>
						</ModalFooter>
					</Form>
				</ModalBody>
			</Modal>
		</section>
	)
}

export default Index
