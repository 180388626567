import React from "react"
import { FaEye, FaEyeSlash } from "react-icons/fa"

const CustomInput = ({
	type,
	value,
	name,
	onChange,
	placeholder,
	fullWidth = false,
	isMissing = false,
	color = "#6B6B6B",
	readOnlyInput,
	setShowPassword,
	showPassword,
	useEyeIcon,
}) => {
	return (
		<div className="relative">
			<input
				readOnly={readOnlyInput}
				className={`w-full h-fit px-phone py-[10px] rounded-default border-[1px] bg-transparent 
				${fullWidth && "md:col-span-2"}
				${
					isMissing
						? "border-red-400 placeholder:text-red-400"
						: color === "#6B6B6B"
						? "border-gray placeholder:text-gray"
						: "border-main placeholder:text-main"
				}
				`}
				placeholder={placeholder}
				type={type === "email" ? "email" : showPassword ? "text" : "password"}
				value={value}
				name={name}
				onChange={onChange}
			/>
			{useEyeIcon && (
				<span
					onClick={() => setShowPassword(!showPassword)}
					className="cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
				>
					{showPassword ? <FaEyeSlash size={16} /> : <FaEye size={16} />}
				</span>
			)}
		</div>
	)
}

export default CustomInput
