import React, { useState } from "react"
import axios from "axios"
import Select from "react-select"
import LoadPage from "../LoadingPage"
import "./Signin.css"

const jobTypeOptions = [
	{ value: "receptionist", label: "Receptionist" },
	{ value: "receptionistCalendars", label: "Receptionist Secondary PC" },
	{ value: "customer", label: "Customer" },
	{ value: "groomer1", label: "Claudia" },
	{ value: "groomer2", label: "Diana" },
	{ value: "groomer3", label: "Groomer 3" },
	{ value: "groomer4", label: "Groomer 4" },
	{ value: "groomer5", label: "Groomer 5" },
	{ value: "groomer6", label: "Groomer 6" },
	{ value: "groomer7", label: "Groomer 7" },
	{ value: "groomer8", label: "Groomer 8" },
	{ value: "groomer9", label: "Groomer 9" },
	{ value: "groomer10", label: "Groomer 10" },
	{ value: "bather", label: "Bather" },
	{ value: "daycare", label: "Daycare" },
]

const colourStyles = {
	control: (styles) => ({ ...styles, backgroundColor: "white" }),
	option: (styles) => ({ ...styles, backgroundColor: "black" }),
}

const EmployeeSignUpForm = ({ getAllEmployees }) => {
	const [formState, setFormState] = useState({
		empFirstName: "",
		empLastName: "",
		password: "",
		password2: "",
		jobType: "",
		errorMsg: "",
		email: "",
		location: "",
		status: "active",
		wage: "",
		username: "",
		loadingAxiosRequest: false,
	})

	const handleChange = (e) => {
		const { id, value } = e.target
		setFormState((prevState) => ({ ...prevState, [id]: value }))
	}

	const onSelectedChanged = (value) => {
		setFormState((prevState) => ({ ...prevState, jobType: value }))
	}

	const onSelectedChangedLocation = (value) => {
		setFormState((prevState) => ({ ...prevState, location: value }))
	}

	const onSelectedChangedStatus = (value) => {
		setFormState((prevState) => ({ ...prevState, status: value }))
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const accessString = localStorage.getItem("JWT")

		if (
			!formState.username ||
			!formState.email ||
			!formState.location ||
			!formState.wage ||
			!formState.password ||
			!formState.password2 ||
			!formState.jobType
		) {
			return
		}
		if (formState.password !== formState.password2) {
			console.log("Passwords don't match!")
			return
		} else if (formState.password.length < 6) {
			alert("Password must be at least 6 characters long!")
			return
		}

		const newEmployee = {
			firstName: formState.empFirstName,
			lastName: formState.empLastName,
			username: formState.username,
			email: formState.email,
			location: formState.location.value,
			wage: formState.wage,
			password: formState.password,
			jobType: formState.jobType.value,
			accountStatus: formState.status.value,
		}

		setFormState((prevState) => ({ ...prevState, loadingAxiosRequest: true }))

		try {
			await axios.post("/auth/signup", newEmployee, {
				headers: { Authorization: `JWT ${accessString}` },
			})
			setFormState((prevState) => ({
				...prevState,
				loadingAxiosRequest: false,
			}))
			getAllEmployees()
			// window.location.href = "/auth/admin_dashboard"
		} catch (error) {
			console.log(error)
			setFormState((prevState) => ({
				...prevState,
				loadingAxiosRequest: false,
			}))
		}
	}

	return (
		<div className="container">
			<div className="row">
				<div
					className="col-md-12"
					style={{
						border: "1px solid white",
						background: "#161515",
						color: "white",
						marginBottom: "30px",
					}}
				>
					<form
						className="white"
						onSubmit={handleSubmit}
						style={{ marginBottom: "50px" }}
					>
						<h2
							className="grey-text text-darken-3"
							style={{ textAlign: "center", marginTop: "15px" }}
						>
							Add a new employee
						</h2>
						<hr style={{ background: "white" }}></hr>
						<div className="input-field">
							<label htmlFor="jobType">* Job Type</label>
							<Select
								value={formState.jobType}
								options={jobTypeOptions}
								placeholder="Job Type:"
								isSearchable={false}
								onChange={onSelectedChanged}
								styles={colourStyles}
							/>
						</div>
						<div className="input-field">
							<label>* Username</label>
							<input
								className="form-control"
								style={{ float: "right" }}
								type="text"
								id="username"
								value={formState.username}
								onChange={handleChange}
							/>
						</div>
						<div className="input-field">
							<label htmlFor="employeeFirstName">* First Name</label>
							<input
								className="form-control"
								style={{ float: "right" }}
								type="text"
								id="empFirstName"
								value={formState.empFirstName}
								onChange={handleChange}
							/>
						</div>
						<div className="input-field">
							<label htmlFor="lastName">* Last Name</label>
							<input
								className="form-control"
								style={{ float: "right" }}
								type="text"
								id="empLastName"
								value={formState.empLastName}
								onChange={handleChange}
							/>
						</div>
						<div className="input-field">
							<label htmlFor="email">* Email</label>
							<input
								className="form-control"
								style={{ float: "right" }}
								type="email"
								id="email"
								value={formState.email}
								onChange={handleChange}
							/>
						</div>
						<div className="input-field">
							<label htmlFor="jobType">* Location</label>
							<Select
								value={formState.location}
								options={[
									{ value: "milton", label: "Milton" },
									{ value: "cambridge", label: "Cambridge" },
									{ value: "all", label: "All" },
								]}
								placeholder="Select location"
								isSearchable={false}
								onChange={onSelectedChangedLocation}
								styles={colourStyles}
							/>
						</div>
						<div className="input-field">
							<label htmlFor="status">Account Status</label>
							<Select
								id="status"
								value={formState.status}
								options={[
									{ value: "active", label: "Active" },
									{ value: "suspended", label: "Suspended" },
									{ value: "terminated", label: "Terminated" },
								]}
								placeholder="Select account status"
								isSearchable={false}
								onChange={onSelectedChangedStatus}
								styles={colourStyles}
							/>
						</div>
						<div className="input-field">
							<label htmlFor="wage">* Wage</label>
							<input
								className="form-control"
								style={{ float: "right" }}
								type="text"
								id="wage"
								value={formState.wage}
								onChange={handleChange}
							/>
						</div>
						<div className="input-field">
							<label htmlFor="password">* Password</label>
							<input
								className="form-control"
								style={{ float: "right", marginBottom: "15px" }}
								type="password"
								id="password"
								value={formState.password}
								onChange={handleChange}
							/>
						</div>
						<div className="input-field">
							<label htmlFor="password">* Confirm Password</label>
							<input
								className="form-control"
								style={{ float: "right", marginBottom: "15px" }}
								type="password"
								id="password2"
								value={formState.password2}
								onChange={handleChange}
							/>
						</div>
						{formState.loadingAxiosRequest ? (
							<LoadPage />
						) : (
							<div className="">
								<button
									style={{ marginTop: "15px" }}
									className="btn-primary btn-block"
								>
									Create Account for employee
								</button>
							</div>
						)}
					</form>
				</div>
			</div>
		</div>
	)
}

export default EmployeeSignUpForm
