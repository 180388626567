import React, { useState, useEffect } from "react"
import {
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	Form,
	FormGroup,
	Label,
	Col,
	Input,
} from "reactstrap"
import LoadPage from "../../components/LoadingPage"
import { FaAddressCard } from "react-icons/fa"
import { RiMoneyDollarBoxFill } from "react-icons/ri"
import PaymentMethodSelector from "../../components/PaymentMethodSelector"
import GiftCardValidator from "../../components/GiftCardValidator"
import axios from "axios"

const ModalToAdd = (props) => {
	const [clientNumber, setClientNumber] = useState("")
	const [clientPhone, setClientPhone] = useState("")
	const [nameBreed, setNameBreed] = useState("")
	const [arrivalTime, setArrivalTime] = useState("")
	const [pickupTime, setPickupTime] = useState("")
	const [cost, setCost] = useState("")
	const [tip, setTip] = useState("")
	const [showAvailablePasses, setShowAvailablePasses] = useState(false)
	const [daycarePayment, setDaycarePayment] = useState("")
	const [giftCardCode, setGiftCardCode] = useState("")
	const [giftCard, setGiftCard] = useState(null)
	const [giftCardAmountToUse, setGiftCardAmountToUse] = useState(null)
	const [isCodeValid, setIsCodeValid] = useState(false)
	const [selectedPaymentMethodOption, setSelectedPaymentMethodOption] =
		useState(null)

	useEffect(() => {
		if (props) {
			setClientNumber(props.valueClientNumber)
			setClientPhone(props.valueClientPhone)
			setNameBreed(props.valueNameBreed)
			setArrivalTime(props.valueArrivalTime)
			setPickupTime(props.valuePickupTime)
			setCost(props.valueCost)
			setTip(props.valueTip)
		}
	}, [props])

	useEffect(() => {
		if (!props.modal) {
			setSelectedPaymentMethodOption(null)
			setGiftCard(null)
			setGiftCardCode(null)
			setGiftCardAmountToUse("")
			setIsCodeValid(false)
		}
	}, [props])

	const validateGiftCardHandler = async () => {
		const response = await axios.post("/api/gift_card/validate", {
			code: giftCardCode,
		})
		const { pass, giftCard } = response.data
		if (pass) {
			setGiftCard(giftCard)
			return true
		} else {
			setGiftCard(null)
			return false
		}
	}

	const submitUpdateHandler = async (e) => {
		e.preventDefault()
		let infoUpdated = {
			clientNumber: clientNumber,
			clientPhone: clientPhone,
			nameBreed: nameBreed,
			arrivalTime: arrivalTime,
			pickupTime: pickupTime,
			cost: cost,
			tip: tip,
		}

		await axios
			.put(`/auth/salaries/update/${props.groomerId}`, infoUpdated)
			.then(() => {
				props.getAllCommissions()
				props.toggleModalToEditCommission()
			})
			.catch((err) => console.log(err))
	}

	const handleChange = (e) => {
		const value = e.target.value
		const inputName = e.target.name
		if (inputName === "clientNumber") {
			setClientNumber(value)
		}
		if (inputName === "clientPhone") {
			setClientPhone(value)
		}
		if (inputName === "nameBreed") {
			setNameBreed(value)
		}
		if (inputName === "arrivalTime") {
			setArrivalTime(value)
		}
		if (inputName === "pickupTime") {
			setPickupTime(value)
		}
		if (inputName === "cost") {
			setCost(value)
		}
		if (inputName === "tip") {
			setTip(value)
		}
	}

	return (
		<Modal
			modalClassName="modal-add-clients"
			isOpen={props.openModal}
			toggle={props.toggleModalToEditCommission}
		>
			<ModalHeader toggle={props.toggleModalToEditCommission}>
				Edit{" "}
				<strong>
					{props.groomerName && String(props.groomerName).toUpperCase()}
				</strong>{" "}
				info
			</ModalHeader>
			<ModalBody>
				<Form onSubmit={submitUpdateHandler} className="form-edit-commission">
					<FormGroup row>
						<Label className=" text-align-center" md={12}>
							<i className="fas fa-calendar"></i> Date: {props.valueDate}
						</Label>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="fas fa-user-circle"></i> Client #
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valueClientNumber}
								name="clientNumber"
								onChange={handleChange}
								placeholder="Client #"
								type="text"
							/>
						</Col>
					</FormGroup>
					{/* <FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="fas fa-user-circle"></i> Phone #
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valueClientPhone}
								name="clientPhone"
								onChange={handleChange}
								placeholder="Phone #"
								type="text"
							/>
						</Col>
					</FormGroup> */}
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="fas fa-paw"></i> Name-breed
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valueNameBreed}
								name="nameBreed"
								onChange={handleChange}
								placeholder="Name - Breed"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="far fa-clock"></i> Arrival time
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valueArrivalTime}
								name="arrivalTime"
								onChange={handleChange}
								placeholder="Arrival Time"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="fas fa-home"></i> Pickup time
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valuePickupTime}
								name="pickupTime"
								onChange={handleChange}
								placeholder="Pickup Time"
								type="text"
							/>
						</Col>
					</FormGroup>
					{props.groomerName === "daycare" && (
						<div className="flex py-2 gap-2 flex-row w-full justify-between">
							<button
								onClick={(e) => {
									e.preventDefault()
									setDaycarePayment("in-person")
									setShowAvailablePasses(false)
								}}
								className={`bg-blue-900 py-2 text-white rounded-md w-1/2 ${
									daycarePayment === "in-person" ? "border-4 border-white" : ""
								}`}
							>
								<RiMoneyDollarBoxFill className="inline mr-2" /> Pay In Person
							</button>
							{props.availablePasses.length ? (
								<button
									onClick={(e) => {
										e.preventDefault()
										setDaycarePayment("membership")
										setShowAvailablePasses(true)
									}}
									className={`bg-blue-900 py-2 text-white rounded-md w-1/2 ${
										daycarePayment === "membership"
											? "border-4 border-white"
											: ""
									}`}
								>
									<FaAddressCard className="inline mr-2" /> Pay with Pass
								</button>
							) : (
								<button
									disabled
									className={`bg-blue-400 py-2 text-white rounded-md w-1/2 ${
										daycarePayment === "membership"
											? "border-4 border-white"
											: ""
									}`}
								>
									No Passes Available
								</button>
							)}
						</div>
					)}

					{props.groomerName === "daycare" &&
					props.availablePasses.length &&
					showAvailablePasses ? (
						<div className="border-2 p-2">
							{props.selectedPassForPayment && (
								<div className="mb-2 bg-cyan-900 p-2">
									<p className="font-bold border bg-yellow-300 p-2 text-center rounded-sm">
										Selected Pass: {props.selectedPassForPayment.name}
									</p>
									<button
										className="bg-blue-400 hover:bg-blue-500 p-2 rounded-sm w-full font-bold text-white"
										onClick={() =>
											props.payDaycareWithPass(props.selectedPassForPayment.id)
										}
									>
										SUBMIT PAYMENT WITH PASS
									</button>
								</div>
							)}
							<h5 className="text-center">Available Passes for Payment</h5>
							<h6 className="text-left">Select One:</h6>
							{props.availablePasses.map((pass) => {
								return (
									<div
										onClick={() => props.setSelectedPassForPayment(pass)}
										className="bg-green-500 my-2 p-2 text-white cursor-pointer hover:bg-green-300"
										key={pass.id}
									>
										<span>Pass Name: {pass.name}</span> <br />
										<span>Available Visits: {pass.numOfVisitsLeft}</span>
									</div>
								)
							})}
							{daycarePayment === "in-person" && (
								<>
									<FormGroup row>
										<Label className=" text-align-center" md={4}>
											<i className="fas fa-dollar-sign"></i> Cost (No taxes)
										</Label>
										<Col lg={8}>
											<Input
												className="form-commission "
												defaultValue={props.valueCost}
												name="cost"
												onChange={props.handleChange}
												placeholder="Cost"
												type="text"
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label className=" text-align-center" md={4}>
											<i className="fas fa-coins"></i> Tip (Including Cents)
										</Label>
										<Col lg={8}>
											<Input
												className="form-commission"
												defaultValue={props.valueTip}
												name="tip"
												onChange={props.handleChange}
												placeholder="Tip"
												type="text"
											/>
										</Col>
									</FormGroup>
								</>
							)}
						</div>
					) : (
						<div className="border-2 border-cyan-500 p-2 rounded-md">
							<PaymentMethodSelector
								selectedOption={selectedPaymentMethodOption}
								setSelectedOption={setSelectedPaymentMethodOption}
							/>
							{selectedPaymentMethodOption &&
							(selectedPaymentMethodOption.value === "debit_credit" ||
								selectedPaymentMethodOption.value === "cash" ||
								selectedPaymentMethodOption.value === "gift_card_in_person" ||
								selectedPaymentMethodOption.value === "e_transfer") ? (
								<>
									<FormGroup row>
										<Label className=" text-align-center" md={4}>
											<i className="fas fa-dollar-sign"></i> Cost (No taxes)
										</Label>
										<Col lg={8}>
											<Input
												className="form-commission "
												defaultValue={props.valueCost}
												name="cost"
												onChange={handleChange}
												placeholder="Cost"
												type="text"
											/>
										</Col>
									</FormGroup>
									{selectedPaymentMethodOption.value !== "cash" && (
										<FormGroup row>
											<Label className=" text-align-center" md={4}>
												<i className="fas fa-coins"></i> Tip (Including Cents)
											</Label>
											<Col lg={8}>
												<Input
													className="form-commission"
													defaultValue={props.valueTip}
													name="tip"
													onChange={handleChange}
													placeholder="Tip"
													type="text"
												/>
											</Col>
										</FormGroup>
									)}
								</>
							) : (
								selectedPaymentMethodOption &&
								selectedPaymentMethodOption.value === "gift_card" && (
									<div>
										<GiftCardValidator
											isClient={false}
											giftCardCode={giftCardCode}
											giftCard={giftCard}
											setGiftCardCode={setGiftCardCode}
											validateGiftCard={validateGiftCardHandler}
											isCodeValid={isCodeValid}
											setIsCodeValid={setIsCodeValid}
											giftCardAmountToUse={giftCardAmountToUse}
											setGiftCardAmountToUse={setGiftCardAmountToUse}
											commissionId={props.commissionId}
											getAllCommissions={props.getAllCommissions}
											toggleModalToEditCommission={
												props.toggleModalToEditCommission
											}
										/>
										<FormGroup row>
											<Label className=" text-align-center" md={4}>
												<i className="fas fa-coins"></i> Tip (Including Cents)
											</Label>
											<Col lg={8}>
												<Input
													className="form-commission"
													defaultValue={props.valueTip}
													name="tip"
													onChange={props.handleChange}
													placeholder="Tip"
													type="text"
												/>
											</Col>
										</FormGroup>
									</div>
								)
							)}
						</div>
					)}

					{props.loadingAxiosReq ? (
						<LoadPage />
					) : (
						<Button className="addPet-btn" block color="warning">
							Submit changes
						</Button>
					)}
					<p className="error-message-registration text-align-center margin-top-small">
						{props.errMsg}
					</p>
				</Form>
			</ModalBody>
		</Modal>
	)
}

export default ModalToAdd
