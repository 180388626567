import React, { useState, useEffect } from "react"
import { teamMembers } from "../../../utils/constants"
import Groomer from "../Groomer"
import axios from "axios"
import moment from "moment"

const Groomers = ({ city, petType, dogService, functionCalled }) => {
	const [appointment, setAppointment] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [soonestAppointment, setSoonestAppointment] = useState(null)
	const [soonestAppointmentGroomer, setSoonestAppointmentGromer] =
		useState(null)

	useEffect(() => {
		if (dogService === "Bath") {
			getSoonestAppointmentBath()
		} else {
			getSoonestAppointment()
		}
	}, [dogService])

	const getSoonestAppointmentBath = async () => {
		setIsLoading(true)
		let url =
			city === "milton"
				? `/schedule/calendar/closest_appointment/milton/dog/bather`
				: `/schedule/calendar/closest_appointment/cambridge/dog/bath_cambridge`
		await axios
			.get(url)
			.then((res) => {
				setAppointment(res.data)
				setIsLoading(false)
			})
			.catch((err) => console.log(err))
	}

	// SETS THE EARLIEST APPOINTMENT OF ALL GROOMERS
	const getSoonestAppointment = async () => {
		try {
			const city = localStorage.getItem("CITY").toLowerCase()
			let earliestAppointment = null
			let earliestAppointmentGroomer = null

			for (let key in teamMembers) {
				if (teamMembers.hasOwnProperty(key)) {
					let groomerName = teamMembers[key].name.toLowerCase()
					if (teamMembers[key].position === "groomer") {
						try {
							const res = await axios.get(
								`/schedule/calendar/closest_appointment/${city}/${petType}/${groomerName}`
							)
							const appointment = res.data?.start
							if (!appointment) {
								console.log(`No appointment found for ${groomerName}`)
								continue
							}
							console.log("Appointment for", groomerName, ":", appointment)
							if (
								!earliestAppointment ||
								new Date(appointment) < new Date(earliestAppointment)
							) {
								earliestAppointment =
									moment(appointment).format("YYYY-MM-DD hh:mm a")
								earliestAppointmentGroomer = groomerName
							}
						} catch (err) {
							console.log(`Error fetching appointment for ${groomerName}:`, err)
						}
					}
				}
			}

			console.log("Earliest Appointment Name:", earliestAppointmentGroomer)

			setSoonestAppointment(earliestAppointment)
			setSoonestAppointmentGromer(earliestAppointmentGroomer)
		} catch (err) {
			console.log("Error fetching appointments:", err)
		}
	}

	return (
		<div className="flex flex-col justify-between min-h-screen">
			<h5 className="text-black font-black text-[30px] md:text-[45px]">
				Choose groomer:
			</h5>
			{soonestAppointment && (
				<button
					onClick={() => {
						functionCalled(soonestAppointmentGroomer)
					}}
					className="bg-pink h-20 mb-4 text-white"
				>
					Click here to select our First available appointment: <br />
					Groomer:{" "}
					{soonestAppointmentGroomer && soonestAppointmentGroomer.toUpperCase()}
					<br />
					Date (YYYY-MM-DD): {soonestAppointment && soonestAppointment}
				</button>
			)}

			{/* Form choices */}
			<div className="w-full h-full grid grid-cols-2 md:grid-cols-3 gap-[50px]">
				{/* Milton */}
				{city === "milton" ? (
					// Dog
					petType === "Dog" ? (
						dogService === "Bath" || dogService === "Bath Express" ? (
							// Not specific Groomer
							<Groomer
								petType={petType.toLowerCase()}
								groomer={teamMembers.bather}
								onClick={() => {
									functionCalled(teamMembers.bather.name)
								}}
							/>
						) : (
							// if not bath
							<>
								{/* <Groomer
									petType={petType.toLowerCase()}
									groomer={teamMembers.adriana}
									onClick={() => {
										functionCalled(teamMembers.adriana.name)
									}}
								/> */}
								{/* <Groomer
									petType={petType.toLowerCase()}
									groomer={teamMembers.claudia}
									onClick={() => {
										functionCalled(teamMembers.claudia.name)
									}}
								/> */}
								<Groomer
									petType={petType.toLowerCase()}
									groomer={teamMembers.paola}
									onClick={() => {
										functionCalled("Paula")
									}}
								/>
								<Groomer
									petType={petType.toLowerCase()}
									groomer={teamMembers.diana}
									onClick={() => {
										functionCalled(teamMembers.diana.name)
									}}
								/>
							</>
						)
					) : (
						// Cat
						<Groomer
							petType={petType.toLowerCase()}
							groomer={teamMembers.paola}
							onClick={() => {
								functionCalled("Paula")
							}}
						/>
					)
				) : // Cambrige

				petType === "Dog" ? (
					// Dog
					dogService === "Bath" || dogService === "Bath Express" ? (
						<>
							<Groomer
								petType={petType.toLowerCase()}
								groomer={teamMembers.baths_cambridge}
								onClick={() => {
									functionCalled(teamMembers.baths_cambridge.name)
								}}
							/>
							{/* <Groomer
								petType={petType.toLowerCase()}
								groomer={teamMembers.peppa}
								onClick={() => {
									functionCalled(teamMembers.peppa.name)
								}}
							/> */}
							{/* {!isLoading ? (
								appointment ? (
									<Groomer
										petType={petType.toLowerCase()}
										groomer={teamMembers.baths_cambridge}
										onClick={() => {
											functionCalled(teamMembers.baths_cambridge.name)
										}}
									/>
								) : (
									<Groomer
										petType={petType.toLowerCase()}
										groomer={teamMembers.peppa}
										onClick={() => {
											functionCalled(teamMembers.peppa.name)
										}}
									/>
								)
							) : null} */}
						</>
					) : (
						// not bath
						<>
							<Groomer
								petType={petType.toLowerCase()}
								groomer={teamMembers.peppa}
								onClick={() => {
									functionCalled(teamMembers.peppa.name)
								}}
							/>
							<Groomer
								petType={petType.toLowerCase()}
								groomer={teamMembers.paola}
								onClick={() => {
									functionCalled("Paula")
								}}
							/>
							{/* <Groomer
								petType={petType.toLowerCase()}
								groomer={teamMembers.adriana}
								onClick={() => {
									functionCalled(teamMembers.adriana.name)
								}}
							/> */}
							<Groomer
								petType={petType.toLowerCase()}
								groomer={teamMembers.mercedes}
								onClick={() => {
									functionCalled(teamMembers.mercedes.name)
								}}
							/>
							{/* <Groomer
								petType={petType.toLowerCase()}
								groomer={teamMembers.claudia}
								onClick={() => {
									functionCalled(teamMembers.claudia.name)
								}}
							/> */}
						</>
					)
				) : (
					// Cat
					<Groomer
						petType={petType}
						groomer={teamMembers.paola}
						onClick={() => {
							functionCalled("Paula")
						}}
					/>
				)}
			</div>
		</div>
	)
}

export default Groomers
