import React from "react"
import { CustomCard } from "./CustomCard"

const ReviewCard = ({ review, className }) => {
	return (
		<CustomCard className={className}>
			<a
				className="w-full h-full flex flex-col gap-[20px] hover:no-underline"
				href="https://www.google.com/search?kgmid=/g/1tywyl3h&hl=en-CA&q=Amazing+Pet+Grooming&shndl=30&shem=lcuae&source=sh/x/loc/osrp/m5/1&kgs=93db4984a259cd29#lrd=0x882b6f0649aa7edb:0xa1616d6c9e9e1545,1,,,,"
				target="_blank"
			>
				<div className="w-full flex gap-[10px] items-center justify-start">
					<img
						className="w-[50px] h-[50px] rounded-full bg-red-100"
						src={review.image}
					/>
					<div className="flex flex-col gap-[0px] justify-center">
						<p className="text-black font-medium mb-0">{review.name}</p>
						<p className="mb-0">⭐️⭐️⭐️⭐️⭐️</p>
					</div>
				</div>
				<p className="text-black">{review.review}</p>
			</a>
		</CustomCard>
	)
}

export default ReviewCard
