import React, { useState, useEffect } from "react"
import {
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	Form,
	FormGroup,
	Label,
	Col,
	Input,
} from "reactstrap"
import LoadPage from "../../components/LoadingPage"
import Select from "react-select"

const colourStyles = {
	// control: (styles) => ({ ...styles, backgroundColor: "white" }),
	option: (styles) => {
		return {
			...styles,
			backgroundColor: "white",
		}
	},
}

let accountStatusOptions = [
	{
		value: "active",
		label: "Active",
	},
	{
		value: "suspended",
		label: "Suspended",
	},
	{
		value: "terminated",
		label: "Terminated",
	},
]

let limitToCambridgeOptions = [
	{
		value: true,
		label: "Yes",
	},
	{
		value: false,
		label: "No",
	},
]

let jobTypeOptions = [
	{
		value: "receptionist",
		label: "Receptionist",
	},
	{
		value: "receptionistCalendars",
		label: "Receptionist Secondary PC",
	},
	{
		value: "customer",
		label: "Customer",
	},
	{
		value: "groomer1",
		label: "Claudia",
	},
	{
		value: "groomer2",
		label: "Diana",
	},
	{
		value: "groomer3",
		label: "Groomer 3",
	},
	{
		value: "groomer4",
		label: "Groomer 4",
	},
	{
		value: "groomer5",
		label: "Groomer 5",
	},
	{
		value: "groomer6",
		label: "Groomer 6",
	},
	{
		value: "groomer7",
		label: "Groomer 7",
	},
	{
		value: "groomer8",
		label: "Groomer 8",
	},
	{
		value: "groomer9",
		label: "Groomer 9",
	},
	{
		value: "groomer10",
		label: "Groomer 10",
	},
	{
		value: "bather",
		label: "Bather",
	},
]

const ModalToAdd = (props) => {
	const [showPassword1, setShowPassword1] = useState(false)
	const [showPassword2, setShowPassword2] = useState(false)

	useEffect(() => {
		if (!props.employeePassword) {
			setShowPassword1(false)
			setShowPassword2(false)
		}
	}, [props.employeePassword])

	return (
		<Modal
			modalClassName="modal-add-clients"
			isOpen={props.openModal}
			toggle={props.toggleModalToEditEmployee}
		>
			<ModalHeader toggle={props.toggleModalToEditEmployee}>
				Edit{" "}
				<strong>
					{props.firstName && String(props.firstName).toUpperCase()}
				</strong>{" "}
				info
			</ModalHeader>
			<ModalBody>
				<p className="error-message-registration text-align-center margin-top-small">
					{props.errMsg}
				</p>
				<Form onSubmit={props.submitFormAll} className="form-edit-commission">
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							First Name
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								value={props.firstName}
								name="firstName"
								onChange={props.handleChange}
								placeholder="First name"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							Last Name
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.lastName}
								name="lastName"
								onChange={props.handleChange}
								placeholder="Last name"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							Email
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.email}
								name="email"
								onChange={props.handleChange}
								placeholder="Email"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							* Location
						</Label>
						<Col lg={8}>
							<Select
								selectedValue={props.location}
								options={[
									{
										value: "milton",
										label: "Milton",
									},
									{
										value: "cambridge",
										label: "Cambridge",
									},
									{
										value: "all",
										label: "All",
									},
								]}
								isSearchable={false}
								onChange={props.onSelectedChangedLocation}
								styles={colourStyles}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							Wage
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.wage}
								name="wage"
								onChange={props.handleChange}
								placeholder="wage"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							Date of Birth (YYYY-MM-DD)
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.dateOfBirth}
								name="dateOfBirth"
								onChange={props.handleChange}
								placeholder="DOB"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							First Day of work (YYYY-MM-DD)
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.firstDayOfWork}
								name="firstDayOfWork"
								onChange={props.handleChange}
								placeholder="1st Day of work"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							Sick days available
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.sickDaysAvailable}
								name="sickDaysAvailable"
								onChange={props.handleChange}
								placeholder="Sick days available"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							Days off available
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.daysOffAvailable}
								name="daysOffAvailable"
								onChange={props.handleChange}
								placeholder="Days off available"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							Vacation days available
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.vacationDaysAvailable}
								name="vacationDaysAvailable"
								onChange={props.handleChange}
								placeholder="Vacation days available"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							* Job Type
						</Label>
						<Col lg={8}>
							<Select
								selectedValue={props.jobType}
								options={jobTypeOptions}
								isSearchable={false}
								onChange={props.onSelectedChanged}
								styles={colourStyles}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							Account Status
						</Label>
						<Col lg={8}>
							<Select
								selectedValue={props.accountStatus}
								options={accountStatusOptions}
								isSearchable={false}
								onChange={props.onSelectAccStatus}
								styles={colourStyles}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							Limit Access To Cambridge
						</Label>
						<Col lg={8}>
							<Select
								selectedValue={props.limitedAccessToCambridgeAdmin}
								options={limitToCambridgeOptions}
								isSearchable={false}
								onChange={props.onSelectLimitToCambridge}
								styles={colourStyles}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							New Password
						</Label>
						<Col lg={8}>
							<i
								onClick={() => setShowPassword1(!showPassword1)}
								className="fa fa-eye fa-sm fa-fw eye_icon"
								aria-hidden="true"
							></i>
							<Input
								className="form-commission"
								defaultValue={props.employeePassword}
								name="employeePassword"
								onChange={props.handleChange}
								placeholder="New Password"
								type={showPassword1 ? "text" : "password"}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							Retype Password
						</Label>
						<Col lg={8}>
							<i
								onClick={() => setShowPassword2(!showPassword2)}
								className="fa fa-eye fa-sm fa-fw eye_icon"
								aria-hidden="true"
							></i>
							<Input
								className="form-commission"
								defaultValue={props.employeePassword2}
								name="employeePassword2"
								onChange={props.handleChange}
								placeholder="Retype Password"
								type={showPassword2 ? "text" : "password"}
							/>
						</Col>
					</FormGroup>
					{props.loadingAxiosReq ? (
						<LoadPage />
					) : (
						<Button className="addPet-btn" block color="warning">
							Submit changes
						</Button>
					)}
				</Form>
			</ModalBody>
		</Modal>
	)
}

export default ModalToAdd
