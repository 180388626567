import React, { useState } from "react"
import { Input, ListGroup } from "reactstrap"
import { FaTimesCircle } from "react-icons/fa"

const DogBreedSearch = ({ breeds }) => {
	const [searchTerm, setSearchTerm] = useState("")
	const [filteredBreeds, setFilteredBreeds] = useState([])
	const [selectedBreed, setSelectedBreed] = useState(null)

	const handleSearch = (e) => {
		const query = e.target.value.toLowerCase()
		setSearchTerm(query)
		if (query.length > 0) {
			const results = breeds.filter((breed) =>
				breed.breedName.toLowerCase().includes(query)
			)
			setFilteredBreeds(results)
		} else {
			setFilteredBreeds([])
		}
	}

	const handleSelect = (breed) => {
		setSelectedBreed(breed)
		setSearchTerm(breed.breedName)
		setFilteredBreeds([])
	}

	return (
		<div className="p-4 h-full">
			<h5>Search for visual breed references</h5>
			<label className="block text-sm font-medium text-gray-700">
				Search for a breed...
			</label>
			<Input
				type="text"
				placeholder="Start typing to search for a breed..."
				value={searchTerm}
				onChange={handleSearch}
				className="mb-2"
			/>
			{filteredBreeds.length > 0 && (
				<ListGroup className="w-full bg-white border border-gray-300 rounded-md shadow-lg">
					{filteredBreeds.map((breed) => (
						<div
							key={breed.breedName}
							className="flex justify-between items-center cursor-pointer hover:bg-gray-200 p-2"
							onClick={() => handleSelect(breed)}
						>
							<div className="flex flex-col">
								<p className="text-sm font-medium">{breed.breedName}</p>
								<p className="text-xs text-gray-500">{breed.category}</p>
							</div>
							<div>
								<img
									src={breed.img_url}
									alt={breed.breedName}
									className="w-24 h-24 object-cover rounded-md"
								/>
							</div>
						</div>
					))}
				</ListGroup>
			)}
			{selectedBreed && (
				<div className="relative mt-4 text-center">
					<button
						className="absolute z-10 top-0 right-0 bg-gray-700 text-white p-1 rounded-full hover:bg-gray-900"
						onClick={() => setSelectedBreed(null)}
					>
						<FaTimesCircle size={36} />
					</button>
					<img
						src={selectedBreed.img_url}
						alt={selectedBreed.breedName}
						className="w-full  mx-auto rounded-lg shadow-md object-cover"
					/>
					<p className="mt-2 text-lg font-semibold">
						Size: {selectedBreed.category}
					</p>
				</div>
			)}
		</div>
	)
}

export default DogBreedSearch
